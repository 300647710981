import { PageProps } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import About from '../containers/about';

const AboutPage: React.FunctionComponent<PageProps> = () => {
  return (
    <Layout>
      <SEO
        title="About Us"
        description="We at War Wolf Games are focused on producing high quality board gaming content."
      />

      {/* <About /> */}
      </Layout>
    );
  };
  
  export default AboutPage;
  
